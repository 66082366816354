import { AppContext, CreditDetailsT, CreditT } from '@brainysoft/lk-components';
import { QueryClient, useQuery } from 'react-query';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { STATUS_CHECK_INTERVAL } from '../config';
import { creditService } from '@brainysoft/lk-components';
import { useEffect, useState } from 'react';

export const useActiveCredit: () => UseQueryResult<CreditT> = () => {
  const { state } = AppContext.useContainer();
  const [options, setOptions] = useState<Omit<UseQueryOptions<unknown, unknown, CreditT, any>, 'queryKey' | 'queryFn'>>(
    {
      refetchInterval: STATUS_CHECK_INTERVAL,
    }
  );

  useEffect(() => {
    if (state.activePage === 'createLoanApp' || state.activePage === 'editLoanApp') {
      setOptions({
        refetchInterval: false,
        refetchOnWindowFocus: true,
      });
    } else {
      setOptions({
        refetchInterval: STATUS_CHECK_INTERVAL,
      });
    }
  }, [state.activePage]);

  return useQuery(
    ['activeCredit'],
    () => {
      return creditService.getActiveCredit() as Promise<CreditT>;
    },
    options
  );
};

export const useActiveCreditDetails: () => UseQueryResult<CreditDetailsT> = () => {
  return useQuery(['activeCreditDetails'], () => creditService.getActiveCreditDetails() as Promise<CreditDetailsT>, {
    refetchInterval: STATUS_CHECK_INTERVAL,
  });
};

export const refreshActiveCredit: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries('activeCredit');
};

export const resetActiveCredit: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.resetQueries('activeCredit');
};
